export const privacyProperties = [
  'whatData',
  'accessData',
  'country',
  'securityMeasures',
  'certificationLocation',
  'surfmarketDpaAgreement',
  'surfnetDpaAgreement',
  'snDpaWhyNot',
  'privacyPolicy',
  'privacyPolicyUrl',
  'otherInfo',
]
